import React, { createContext, useState } from 'react';

type LoadingContextData = {
  loading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
};

export const LoadingContext = createContext({} as LoadingContextData);

export const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoader = (): void => {
    setLoading(true);
  };

  const hideLoader = (): void => {
    setLoading(false);
  };

  return (
    <LoadingContext.Provider value={{ loading, showLoader, hideLoader }}>
      {children}
    </LoadingContext.Provider>
  );
};
