// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from 'react-query';
import { store } from './redux/storeConfig/store';

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability';
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

import 'react-loading-skeleton/dist/skeleton.css';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';
import { ChangeLogoProvider } from './utility/context/ChangeLogoContext';
import { LoadingProvider } from './utility/context/LoadingContext';
import { LoadingGlobal } from './@core/components/loading-global';
import { queryClient } from './services/queryClient';
import { OnboardingProvider } from './utility/context/AuthOnboardingContext';
import { CreateOnboardingProvider } from './utility/context/CreateOnboardingContext';
import { Check2FactorAuthenticationProvider } from './utility/context/Check2FactorAuthenticationContext';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <QueryClientProvider client={queryClient}>
            <LoadingProvider>
              <ChangeLogoProvider>
                <OnboardingProvider>
                  <CreateOnboardingProvider>
                    <LazyApp />
                    <ToastContainer newestOnTop />
                    <LoadingGlobal />
                  </CreateOnboardingProvider>
                </OnboardingProvider>
              </ChangeLogoProvider>
            </LoadingProvider>
          </QueryClientProvider>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
