import { SVGAttributes } from 'react';
import Avatar from '@components/avatar/avatar';
import { Check, X } from 'react-feather';
import { Bounce, toast } from 'react-toastify';

interface IconProps extends SVGAttributes<SVGElement> {
  color?: string;
  size?: string | number;
}

type ToastProps = {
  title: string;
  description: string;
  color: string;
  icon: IconProps;
};

type IRequestToast = {
  title: string;
  description: string;
};

const Toast = ({ title, description, color, icon }: ToastProps) => (
  <>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={icon} />
        <h6 className={`text-${color} ml-50 mb-0`}>{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span>{description}</span>
    </div>
  </>
);

export function toastError({ title, description }: IRequestToast) {
  toast.error(
    <Toast
      title={title}
      description={description}
      color="danger"
      icon={<X size={12} />}
    />,
    {
      transition: Bounce,
      hideProgressBar: true,
    }
  );
}

export function toastSuccess({ title, description }: IRequestToast) {
  toast.success(
    <Toast
      title={title}
      description={description}
      color="success"
      icon={<Check size={12} />}
    />,
    {
      transition: Bounce,
      hideProgressBar: true,
    }
  );
}
