import api from '@src/services/api';
import apiOnboarding from '@src/services/api-onboarding';
import { AxiosResponse } from 'axios';
import { createContext, useCallback, useState } from 'react';

export type Operator = {
  email?: string;
  name?: string;
  document?: string;
  typeDocument?: string;
  phone?: string;
  documentForValidation?: null;
};

export type DataOnboarding = {
  systemOrigin?: string;
  idOperator?: string;
  id?: string;
  companyType?: string;
  documentType?: string;
  document?: string;
  commercialName?: string;
  fantasyName?: string;
  cnaePrimary?: string;
  companyFormationCode?: string;
  companyFormationIdentifier?: string;
  siteCompany?: string;
  foundationDate?: string;
  address?: {
    street: string;
    zipCode: string;
    neighborhood: string;
    city: string;
    state: string;
    number: string;
    complement: string;
  };
  partners?: {
    name: string;
    email: string;
    document: string;
    documentType: string;
  }[];

  representative?: {
    name: string;
    motherName: string;
    email: string;
    phone: string;
    cpf: string;
    rg: string;
    birthdate: string;
    stateDocument: string;
    organIssuer: string;
    documentType: string;
    street: string;
    zipCode: string;
    neighborhood: string;
    city: string;
    state: string;
    number: string;
    complement: string;
  };
  contact?: {
    name: string;
    email: string;
    phone: string;
    cpf: string;
    birthDate: string;
  };
  account?: {
    typeAccount: string;
    ownerOfTheAccount: string;
    codeBacodeBankAccountnk: string;
    codeAgencyAccount: string;
    digitAgencyAccount: string;
    numberAccount: string;
    digitAccount: string;
  };
};

export type CreateOnbordingContextData = {
  onboardingData: DataOnboarding;
  addOnboardingData: (data: DataOnboarding) => void;
  saveOnboardingData: (
    url: string,
    data: DataOnboarding
  ) => Promise<AxiosResponse>;
  getEmailOperatorByToken: () => Promise<string>;
  getIdAndSystemOriginOperatorByEmail: (email: string) => Promise<{
    id: string;
    systemOrigin: string;
  }>;
  getCompanyByIdOperator: (id: string) => Promise<DataOnboarding>;
  finalizeRegistration: (id: string, systemOrigin: string) => Promise<void>;
  invalideTokenInvite: () => Promise<void>;
  handleCreateOrGetOperator: ({
    name,
    phone,
    documentForValidation,
    typeDocument,
    document,
    email,
  }: Operator) => Promise<void>;
};

export const CreateOnboardingContext = createContext(
  {} as CreateOnbordingContextData
);

export const CreateOnboardingProvider: React.FC = ({ children }) => {
  const [onboardingData, setOnboardingData] = useState<DataOnboarding>(
    {} as DataOnboarding
  );

  const addOnboardingData = useCallback((data: DataOnboarding): void => {
    setOnboardingData((oldState) => {
      return { ...oldState, ...data };
    });
  }, []);

  const saveOnboardingData = useCallback(
    async (url: string, data: DataOnboarding): Promise<AxiosResponse> => {
      const res = await apiOnboarding.post(url, data, {
        headers: { 'Content-type': 'application/json' },
      });
      return res;
    },
    []
  );

  const getEmailOperatorByToken = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const response = await api.get(`/invide-user/user-by-token/${token}`);
    if (response) {
      return response?.data?.response?.email;
    }
  }, []);

  const finalizeRegistration = useCallback(
    async (id: string, systemOrigin: string) => {
      await apiOnboarding.post(
        'api/company/update/company-full/finalize-registration',
        {
          systemOrigin,
          idCompanyFull: id,
        }
      );
    },
    []
  );

  const invalideTokenInvite = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    await api.post('invide-user/invalidate-token-invite', {
      tokenInvite: token,
    });
  }, []);

  const getIdAndSystemOriginOperatorByEmail = useCallback(
    async (email: string) => {
      const { data } = await apiOnboarding.get(
        `api/operator/by-email/${email}?systemOrigin=${process.env.REACT_APP_SYSTEM_ORIGIN}`
      );

      const { id, systemOrigin } = data.data;

      return {
        id,
        systemOrigin,
      };
    },
    []
  );

  const getCompanyByIdOperator = useCallback(async (id: string) => {
    try {
      const dataCompany = await apiOnboarding.get(
        `api/company/company-full/by-operator?idOperator=${id}&systemOrigin=${process.env.REACT_APP_SYSTEM_ORIGIN}`
      );

      return dataCompany?.data?.data;
    } catch (error) {
      return null;
    }
  }, []);

  const handleCreateOrGetOperator = async ({
    name,
    phone,
    documentForValidation,
    typeDocument,
    document,
    email,
  }: Operator) => {
    const userData = localStorage.getItem('userData');
    const data = userData ? JSON.parse(userData) : null;

    try {
      const res = await apiOnboarding.get(
        `api/operator/by-email/${email}?systemOrigin=${process.env.REACT_APP_SYSTEM_ORIGIN}`
      );

      await apiOnboarding.post('api/operator/update-data-by-email', {
        systemOrigin: process.env.REACT_APP_SYSTEM_ORIGIN,
        currentEmail: email,
        newEmail: email,
        name,
        typeCompany: 'sto',
        toDocument: document,
        typeDocument,
        whitelabelId: data.company.companyId.toString(),
        phone,
        documentForValidation,
        typeRegister: 'TO_CREATE_BY_VALIDATION',
      });
    } catch (error) {
      await apiOnboarding.post('api/operator/create', {
        systemOrigin: process.env.REACT_APP_SYSTEM_ORIGIN,
        name,
        email,
        typeCompany: 'sto',
        toDocument: document,
        typeDocument,
        whitelabelId: data.company.companyId.toString(),
        phone,
        documentForValidation,
        typeRegister: 'TO_CREATE_BY_VALIDATION',
      });
    }
  };

  return (
    <CreateOnboardingContext.Provider
      value={{
        onboardingData,
        addOnboardingData,
        saveOnboardingData,
        getEmailOperatorByToken,
        getIdAndSystemOriginOperatorByEmail,
        getCompanyByIdOperator,
        finalizeRegistration,
        invalideTokenInvite,
        handleCreateOrGetOperator,
      }}
    >
      {children}
    </CreateOnboardingContext.Provider>
  );
};
