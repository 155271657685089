import api from '@services/api';
import axios, { AxiosError } from 'axios';
import { toastError } from '@src/utility/Toast';

const isFeatureEnabled = async (featureName: string) => {
  try {
    const { data } = await api.get('system/2fa', {
      params: {
        function: featureName,
      },
    });
    return data;
    // return true;
  } catch (error) {
    let message = null;

    const err = error as Error | AxiosError;

    if (axios.isAxiosError(err)) {
      message =
        err.response?.data.message || 'Algo deu errado ao efetuar o login';
    } else {
      message = 'Server error';
    }

    toastError({ title: 'Atenção', description: message });
  }
};

export default isFeatureEnabled;
