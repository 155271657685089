import axios from 'axios';

import useJwt from '@src/auth/jwt/useJwt';

const token = useJwt.getTokenOnboarding();

const apiOnboarding = axios.create({
  baseURL: process.env.REACT_APP_API_ONBOARDING_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default apiOnboarding;
