import api from '@src/services/api';
import { createContext, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import isFeatureEnabled from '@src/services/feature-control';
import axios from 'axios';
import useJwt from '@src/auth/jwt/useJwt';
import { toastError } from '../Toast';
import { useLoadingGlobal } from '../hooks/useLoadingGlobal';

type Check2FactorAuthenticationData = {
  modal?: string;
};

export const Check2FactorAuthenticationContext = createContext(null);

export const Check2FactorAuthenticationProvider: React.FC = ({ children }) => {
  const { showLoader, hideLoader } = useLoadingGlobal();
  const navigate = useHistory();
  const token = useJwt.getToken();

  const handleCheck = useCallback(async () => {
    const is2faEnabled = await isFeatureEnabled('two_factor_authentication');
    if (is2faEnabled) {
      try {
        const userData = localStorage.getItem('userData') ?? null;

        if (!userData) {
          navigate.push('/login');
          return;
        }

        const user = JSON.parse(userData);

        const response = await api.post('2fa/is-authenticated', {
          userId: user.iduser,
        });

        if (response.data.isAuthentication === 0) {
          navigate.push('/auth');
        }
      } catch (error) {
        navigate.push('/login');
        toastError({
          title: 'Ops! Algo deu errado',
          description:
            'Não foi possível validar o usuário, tente fazer o login novamente',
        });
      }
    }
  }, []);

  const handleRequires2faAuth = useCallback(async () => {
    try {
      const me = await axios.get(`${process.env.REACT_APP_API_URL}profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (me.data.requires2faAuth) {
        await handleCheck();
      }
    } catch (error) {
      //
    }
  }, [token, handleCheck]);

  useEffect(() => {
    handleRequires2faAuth();
  }, [handleRequires2faAuth]);
  return (
    <Check2FactorAuthenticationContext.Provider value={null}>
      {children}
    </Check2FactorAuthenticationContext.Provider>
  );
};
